import React from 'react';
import Page from '../../functional/components/Page';
import Button from '../../functional/shared/Button';

import img from '../../assets/just-in-time.jpg';
import imgLogo from '../../assets/just-in-time-logo.png';


const IndexJustInTime = () => {
  return (
    <Page>
      <section className={'just-in-time'}>

        <img src={img} alt={''}/>

        <img src={imgLogo} alt={''} className={'jit-logo'}/>

        <div className={'content'}>
          <div className={'title txt-c-pr'}>
            Just-in-time Service unserer Fertigung<br/>
            Morgens bestellt – nachmittags geliefert
          </div>

          <div className={'info-text txt-c-se'}>
            <div>
              Gemäß unserer Unternehmensphilosophie verstehen wir uns als Dienstleister der
              Industrie. Unsere Dienstleistungen beinhalten naturgemäß zwar einen sehr hohen
              Fertigungsanteil. In der Praxis stehen serviceorientierte Aspekte bei uns jedoch stets
              im Vordergrund.
            </div>
            <div>
              Vor allen Dingen die absolut termingerechte Projektabwicklung und Lieferung.
              Wir muten unseren Kunden weder lange Vorlaufzeiten noch eine unwirtschaftliche
              Lagerhaltung zu, sondern bieten eine professionelle Just-in-time Dienstleistung, die
              diesen Namen auch verdient. Unsere eng aufeinander abgestimmten Planungs- und
              Fertigungsprozesse sichern diesen bewährten Just-in-time Service.
            </div>
            <Button label={'Ihre Anfrage >'} color={'light-brown'} className={'p-a font-secondary'} onClick={() => console.log('click')}/>

          </div>
        </div>
      </section>
    </Page>
  );
};


export default IndexJustInTime;