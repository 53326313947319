import React from 'react';
import {NavLink} from 'react-router-dom';

 const Navbar = ({nav, toggleMobile}) => {
  const navItems = [
    {path: '/', title: 'Home'},
    {path: '/#Produkte', title: 'Produkte'},
    {path: '/#Produkte', title: 'Service'},
    {path: '/#Portrait', title: 'Portrait'},
    {path: '/kontakt', title: 'Kontakt'},
  ];

  return (
    <nav className={`${nav ? '' : toggleMobile ? 'mobile-' : 'disabled-'}site-nav`}>
      <div className={'nav'}>
        <ul>
          {navItems.map((item, i) => <li key={i}>{item.title === 'Kontakt' 
            ? <NavLink exact to={item.path} activeClassName={'active'}>{item.title}</NavLink>
            : <a href={item.path} target={''}>{item.title}</a>}</li>)}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
