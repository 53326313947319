import React from 'react';
import Page from '../../functional/components/Page';

import Werk1 from '../../assets/werk-1.jpg';
import Werk2 from '../../assets/werk-2.jpg';
import Werk3 from '../../assets/werk-3.jpg';
import Werk4 from '../../assets/werk-4.jpg';


const IndexStandorte = () => {
  return (
    <Page>
      <section className={'standorte'}>

        <div className={'content'}>
          <div className={'title txt-c-pr'}>4 Standorte</div>
        </div>

        <div className={'content container'}>
          <img src={Werk1} alt={''}/>
          <div className={'info'}>
            <div>Werk 1</div>
            <div>
              <span className={'txt-b'}>Harpers Holzpack GmbH & Co. KG</span><br/>
              Jägerhausstraße 115<br/>
              52224 Solberg-Zweifall
            </div>
            <div>Tel. <a href={'tel:0049240298580'}>02402-9858-0</a></div>
          </div>
        </div>

        <div className={'content container'}>
          <img src={Werk2} alt={''}/>
          <div className={'info'}>
            <div>Werk 2</div>
            <div>
              Werkstraße<br/>
              52224 Solberg-Zweifall
            </div>
          </div>
        </div>

        <div className={'content container'}>
          <img src={Werk3} alt={''}/>
          <div className={'info'}>
            <div>Werk 3</div>
            <div>
              Münsterau<br/>
              52224 Solberg-Zweifall
            </div>
          </div>
        </div>

        <div className={'content container'}>
          <img src={Werk4} alt={''}/>
          <div className={'info'}>
            <div>Werk 4</div>
            <div>
              Münsterau<br/>
              52224 Solberg-Zweifall
            </div>
          </div>
        </div>

      </section>
    </Page>
  )
};


export default IndexStandorte;