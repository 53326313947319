import React from 'react';
import Headbar from './Headbar';
import Footer from './Footer';
import {includes} from 'ramda';


const Page = ({id, children}) => {
  const showFooter = includes(id, ['home']);

  return (
    <div className={'wrapper'}>
      <Headbar/>


      <div className={'page'}>

        <div className={'main'}>
          {children}
        </div>
        
      </div>

      {showFooter && <Footer/>}
    </div>
  );
};


export default Page;
