import React from 'react';
import Page from '../../functional/components/Page';

import img from '../../assets/impressum.jpg'


const IndexImpressum = () => {
  return (
    <Page>
      <section className={'impressum'}>
        <img src={img} alt={''}/>

        <div className={'content'}> 
          <div className={'im-title txt-c-pr'}>Impressum</div>

          <div className={'im-content'}>
            <div className={'hb'}>
              <div>
                Firma: Harpers Holzpack GmbH & Co. KG
              </div>

              <div>
                Adresse:<br/>
                Jägerhausstraße 115<br/>
                52224 Stolberg-Zweifall
              </div>

              <div>
                Tel. <a href="tel:0049240298580">02402-9858-0</a><br/>
                Fax 02402.75237<br/>
                <a href={'mailto:info@harpers-holzpack.de'}>info@harpers-holzpack.de</a><br/>
                <a href={'/'}>www.harpers-holzpack.de</a>
              </div>

              <div>
                Sitz Stolberg, Registergericht Eschweiler<br/>
                HRB 0975, USt-IdNr. DE 220376361<br/>
                Steuer-Nr. 202/5805/0409
              </div>

              Geschäftsführer/Inhaber: Bernd Jansen<br/>
            </div>

            <div>
              Gestaltung Corporate- und Web Design<br/>
              Fried Hoven Corporaid, Aachen<br/>
              <a href={'www.corporaid.de'}>www.corporaid.de</a>
            </div>

          </div>
        </div>
      </section>
    </Page>
  );
};


export default IndexImpressum;