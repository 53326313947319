import React from 'react';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import IndexHome from './pages/home/IndexHome';
import IndexKontakt from './pages/kontakt/IndexKontakt';
import IndexImpressum from './pages/impressum/IndexImpressum';
import IndexDatenschutz from './pages/datenschutz/IndexDatenschutz';
import IndexStandorte from './pages/standorte/IndexStandorte';
import IndexJustInTime from './pages/just-in-time/IndexJustInTime';


const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        
        <Route exact path={'/'} component={IndexHome}/>
        <Route exact path={'/kontakt'} component={IndexKontakt}/>

        <Route exact path={'/standorte'} component={IndexStandorte}/>
        <Route exact path={'/justintime'} component={IndexJustInTime}/>
        
        <Route exact path={'/impressum'} component={IndexImpressum}/>
        <Route exact path={'/datenschutz'} component={IndexDatenschutz}/>

        <Redirect to={'/'}/>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes;