import React from 'react';
import Routes from './Routes';
import './theme/theme.scss';

const App = () => {
  return (
    <Routes/>
  );
}

export default App;
