import React from 'react';


const Button = ({label, color, className = '',  ...rest}) => {
  return (
    <div className={`btn btn-${color || 'brown'} ${className}`} {...rest}>
      {label}
    </div>
  );
};


export default Button;
