import React from 'react';
import {Link} from 'react-router-dom';
import Navbar from './Navbar';
import Button from '../shared/Button';
import harpersLogo from '../../assets/harpers-logo_seit.png';

import telIcon from '../../assets/icon-tel.jpg';
import mailIcon from '../../assets/icon-mail.jpg';

 const Headbar = () => {
   const [nav, setNav] = React.useState(false);
   const [mobileToggle, setMobileToggle] = React.useState(false);

   React.useLayoutEffect(() => {
    const handleResize = () => {
      if(window.innerWidth > 992) {
        setNav(true);
      } else {
        setNav(false);
      }
    }
    handleResize();

    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
   }, []);
   
   return (
    <header>
      <div className="header-container">
        <div className={'d-f'}>
          <Link to={'/'}>
            <img src={harpersLogo} alt={'harpers logo'}/>
          </Link>

          <div className={'mobile-nav-toggle'} onClick={() => setMobileToggle(!mobileToggle)}>
            <div id={'nav-ham'} className={'nav-toggle'}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        
        <div className={'elements'}>
          <div className={'actions'}>
            <a href={'tel:0049240298580'}><img src={telIcon} alt={''} /></a>
            <a href={'mailto:info@harpers-holzpack.de'}><img src={mailIcon} alt={''} /></a>
            <Button label={'Unsere Angebote >'} color={'brown'} onClick={() => console.log('Unsere Angebote')} />
          </div>
          <Navbar nav={nav} toggleMobile={mobileToggle} />
        </div>
        

        {/* <Button label={'Aktuelle Angebote'} onClick={() => console.log('Aktuelle Angebote')}/> */}

      </div>

    </header>
  );
}

export default Headbar;