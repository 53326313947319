import React from 'react';
import Page from '../../functional/components/Page';

import img from '../../assets/impressum.jpg'


const IndexKontakt = () => {
  return (
    <Page id={'kontakt'}>
      <section className={'kontakt'}>
        <div className={'content'}>
          <div className={'infos'}>
            <div className={'title txt-c-pr'}>Kontaktinfo</div>

            <div className={'contact txt-c-se'}>
              Büroanschrift Werk 1<br/><br/>

              <div>
                <span className={'txt-c-se txt-b'}>Harpers Holzpack GmbH & Co. KG</span><br/>
                Jägerhausstraße 115<br/>
                52224 Solberg-Zweifall
              </div>
              <div>
                Tel. <a href={'tel:0049240298580'}>02402-9858-0</a><br/>
                Fax 02402.75237<br/>
                <a href={'mailto:info@harpers-holzpack.de'}>info@harpers-holzpack.de</a>
              </div>
              <div>
                Ansprechpartner:<br/>
                Bernd Jansen
              </div>
            </div>
          </div>
          <img src={img} alt={''}/>
        </div>
      </section>
    </Page>
  )
}

export default IndexKontakt;