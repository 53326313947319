import React from 'react';
import {NavLink} from 'react-router-dom';
import Button from '../shared/Button';

import hLogo from '../../assets/harpers-logo.png';
import unserHolzLogo from '../../assets/unser-holz-logo.png';
import hpeLogo from '../../assets/hpe-logo.png';
import jitLogo from '../../assets/just-in-time-logo.png';

import homeIcon from '../../assets/icon-home.jpg';
import folderIcon from '../../assets/icon-page.jpg';

import infoPDF from '../../assets/info_harpers-holzpack.pdf';


const FooterNavbar = () => {
  const navItems = [
    {path: '/impressum', title: 'Impressum'},
    {path: '/datenschutz', title: 'Datenschutz'},
  ];

  return (
    <nav className={'site-nav'}>
      <div className={'nav'}>
        <ul>
          {navItems.map((item, i) => <li key={i}><NavLink exact to={item.path} activeClassName={'active'}>{item.title}</NavLink></li>)}
        </ul>
      </div>
    </nav>
  );
};


 const Footer = () => {
  return (
    <footer>

      <div className={'footer-container'}>

        <div className={'f-fr'}>
          <div className={'logos'}>
            <a href={'/'}><img src={hLogo} alt={'harpers logo'} /></a>
            <a href={'http://www.hpe.de'}><img src={hpeLogo} alt={''} /></a>
            <img src={unserHolzLogo} alt={''} />
            <img src={jitLogo} alt={''} />
          </div>
          <div className={'actions'}>
            <a href={'/'}><img src={homeIcon} alt={''} /></a>
            <a href={infoPDF}><img src={folderIcon} alt={''} /></a>
            <Button label={'Info Donwload >'} color={'green'} onClick={() => window.open(infoPDF)} />
          </div>
          
        </div>

        <div className={'f-sr'}>
          <div className={'copyright'}>&copy; 2019 <span className={'txt-b'}>Harpers Holzpack GmbH & Co. KG</span></div>

          <FooterNavbar/>

          <div className={'footer-box'}>
            <div className={'conctact txt-c-pr'}><a href={'tel:0049240298580'}>Hotline 02402-9858-0</a> &bull; <a href={'mailto:info@harpers-holzpack.de'}>E-Mail</a></div>
            <div className={'txt-c-pr'}><a href={'https://www.google.de/maps/place/Harpers+Holzpack+GmbH+%26+Co.+KG/@50.7105134,6.269936,17z/data=!3m1!4b1!4m5!3m4!1s0x47bf63048ce6f70b:0xc34c3ed1e5af58a1!8m2!3d50.71051!4d6.27213'}>Büro Werk 1</a> &bull; Werk 2 &bull; Werk 3 &bull; Werk 4</div>
          </div>
        </div>
      </div>

    </footer>
  );
}


export default Footer;