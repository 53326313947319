import React from 'react';
import {useHistory} from 'react-router-dom';
import Page from '../../functional/components/Page';
import Button from '../../functional/shared/Button';


const IndexHome = () => {
  const history = useHistory();

  return (
    <Page id={'home'}>
      <section className={'home'}>

        <div className={'hb b1'}>
          <div className={'content'}>
              <div className={'title txt-c-pr'}>
                Packmittel nach Maß – Just-in-time-Produktion<br/>
                Verpackungen aller Art aus Holz und Sperrholz
              </div>

              <div className={'sub-title txt-c-se'}>
                Seit 1899 ist <span className={'txt-i'}>Harpers Holzpack</span> der zuverlässige Packmittel-Partner der Industrie<br/>
                für maßgefertigte Holzpackmittel aller Art.
              </div>
          </div>
        </div>

        <div className={'hb b2 btn-content'}>
          <Button label={'Holz ist Natur >'} color={'brown'} className={'p-a font-secondary'} onClick={() => console.log('click')}/>
        </div>

        <div id={'Produkte'} className={'hb b3'}>
          <div className={'btn-content'}>
            <Button label={'Just-In-Time >'} color={'green'} className={'p-a font-secondary'} onClick={() => history.push('/justintime')}/>
          </div>
        </div>

        <div className={'hb b4'}>
          <div className={'content'}>
            Unser Lieferprogramm umfasst:<br/>
            Kisten · Paletten · Sonder-Paletten<br/>
            Spezial-, Schwergut- und Exportverpackungen
          </div>
        </div>

        <div className={'hb b5'}>
          <div className={'content'}>
            <div className={'space txt-n'}>
              Unser Schwerpunkt liegt in der Entwicklung, der Produktion und dem Service<br/>
              innovativer Qualitätspackmittel für die Sanitär-, Glas-, Metall-, Maschinenbau- und<br/>
              Chemische Industrie, aus dem nachwachsenden Rohstoff Holz und Sperrholz.
            </div>

            Entwicklung · Herstellung · Behandlung · Logistik · Versand · Service<br/>
            Qualitäts-Sicherung · Sicherheits-Management · Just-in-Time Service
          </div>
          <div className={'btn-content'}>
            <Button label={'Ihre Anfrage >'} color={'light-brown'} className={'p-a font-secondary'} onClick={() => console.log('click')}/>
          </div>
        </div>

        <div className={'hb b6'}>
          <div className={'btn-content'}>
            <Button label={'Umweltbilanz >'} color={'green'} className={'p-a font-secondary'} onClick={() => window.open('http://www.hpe.de/oekobilanz.html')}/>
          </div>
          <div className={'content'}>
            <div className={'title txt-c-pr'}>Naturstoff Holz der Umwelt zuliebe</div>
            <div className={'sub-title txt-c-se'}>
              Verpackungen aus dem nachwachsenden Rohstoff Holz sind angemessen und<br/>
              innovativ. Die steigende Problematik der Produktion und Entsorgung von Wertstoffen<br/>
              belastet sowohl die nachhaltige Wirtschaft als auch unsere Umwelt, also jeden von<br/>
              uns. Die sorgfältige Wahl der Rohstoffe in Verbindung mit energiesparenden und<br/>
              modernen Fertigungsmethoden bieten <span className={'txt-i'}>Harpers Holzpack</span> und unseren Partnern<br/>
              Potential für eine nachhaltige Unternehmenspolitik.
            </div>
          </div>
        </div>

        <div className={'hb b7'}>
          <div className={'content'}>
            <div className={'title txt-c-pr'}>Der ideale Werkstoff für Packmittel</div>
            <div className={'sub-title txt-c-se'}>
            Die Werkstoffe Holz und Sperrholz bieten in allen Packmittel-Fragen die optimale<br/>
            Lösung für die Aufgabenstellungen der produzierenden Industrie. Aufgrund seiner<br/>
            optimalen Eigenschaften, leichten Verarbeitung und Verfügbarkeit ist Holz der<br/>
            ideale Werkstoff für Verpackungen.
            </div>
          </div>
        </div>

        <div id={'Portrait'} className={'hb b8'}>
          <div className={'btn-content'}>
            <Button label={'4 Standorte >'} color={'brown'} className={'p-a font-secondary'} onClick={() => history.push('/standorte')}/>
          </div>
          <div className={'content'}>
            <div className={'title txt-c-pr'}>120 Jahre Tradition als zuverlässiger Industrie Partner</div>
            <div className={'sub-title txt-c-se'}>
              Seit 1899 ist Harpers Holzpack ein Begriff für Holzhandel, Holzverarbeitung und<br/>
              Qualitätsverpackungen in der alten Industriestadt Stolberg, im Großraum Aachen-Köln<br/>
              sowie der Euregio Rhein-Maas.
            </div>
          </div>
          
        </div>

      </section>
    </Page>
  )
}


export default IndexHome;
